// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.topBarImageAlignment {
    text-align: center;
}

body a:hover {
    color: $color-template;
}

.theme-light .form input,
.theme-light .form textarea,
.theme-light .topbar__link-icon {
    color: black;
}

.themeColorText {
    color: $color-template;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 0;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
}

.theme-white {
    // border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    display: none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    display: none;
}

.p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
    width: 100%
}

.p-column-filter-menu-button,
.p-column-filter-clear-button {
    color: white
}

body .p-datatable .p-datatable-tbody>tr.p-highlightHoliday {
    background-color: #fbff2b;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightWeekend {
    background-color: #1efd00;
}

body .p-datatable .p-datatable-tbody>tr.p-highlightLessThan6Hrs {
    background-color: #ff0000;
}

body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: $color-white;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    font-size: 15px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
    background: $button-text-color;
    color: $button-bg-color;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
    height: 32px;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

body .p-button {
    background-color: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
    padding: 8px;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background-color: $button-bg-color;
        border-color: $button-hover-color !important;
        color: $button-text-color !important;
    }
}

.p-button:enabled:hover,
.p-button:enabled:active {
    background-color: $button-hover-color;
    border-color: $button-hover-color ;
    color: $button-text-color;
}

.p-button.p-button-outlined {
    color: $button-bg-color;
}

.p-button.button-filter {
    padding: 8px 14px;
    font-size: small;
    height: 42px;
}

.p-button-sm.button-filter {
    padding: 15px;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open {
    background: $button-bg-color;
    border-color: $button-bg-color;
    color: $button-text-color;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 390px;
}

.p-column-title {
    text-transform: capitalize !important;
    font-size: 16px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

//kpiTable
.kpiTable {
    background-color: $color-template;
    color: black;
}

.colorLegend {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

body .p-multiselect-panel .p-multiselect-items {
    color: black
}

// body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
//     background-color:$color-template;

// }
body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: $color-template;
    border-color: $color-template
}

body .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: $color-template !important;
    border-color: $color-template !important;
}

body .p-input-overlay-visible {
    z-index: 9999 !important;
}

.color-picker__popover {
    z-index: 9999;
}

.calendar .rbc-off-range-bg {
    background-color: #e5e5e5;
}

.textElipses {
    font-weight: bold;
    color: $color-template;
    cursor: pointer;
    .link{
        font-size: 16px;
        font-weight: bold ;
        a {
            text-decoration: none !important;
        }
    }
    >a>img {
        width: 40px;
        height: 20px;
        margin: 5px;
    }
}

// .p-dropdown .p-dropdown-trigger {
//     height: 100%;
// }
// .css-vj8t7z{
//     min-height: 0 !important
// }
// .css-1hwfws3{
//     position: static !important;
// }
// .css-10nd86i react-select{
//     height: 33px
// }
// .css-2o5izw .react-select__control .react-select__control--is-focused{
//     height: 33px;
// }

.botmessagesText {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 8px;
    padding-left: 7px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: 5px;
    align-self: flex-start;
    width: 90%;
    border-color: red
}

.usermessagesText {
    background-color: #f0f1ef;
    padding-top: 5px;
    padding-left: 7px;
    margin-right: 5px;
    margin-top: 7px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 90%;
    text-align: left
}

.p-multiselect-label-container {
    height: 30px;
}

.p-autocomplete-input-token input {
    border: none
}

.logout_modal_width {
    max-width: 400px;
}

.call_action_column {
    text-align: 'center';
    text-decoration: underline;
    color: $color-template;
    font-weight: bold;
    cursor: pointer;
}

.p-datatable-row {
    cursor: initial !important;
}

.p-datatable-row:hover {
    background: transparent
}

.settings_form {
    width: 23%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: solid 1px lightgray;
}

.form .settings_form:focus,
.form .settings_form:active {
    outline: none;
    border-color: #ffcd08;
}

.pending_badge,
.started_badge {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
}

.vertical_middle {
    justify-content: center;
    align-items: center;
    display: flex;
}

body .p-component {
    font-family: unset;
}

.pagenumber-indicator {
    display: flex;
    height: 100%;
    align-items: center;
}

.rdw-editor-toolbar {
    img {
        height: 20px;
        width: 20px;
    }
}

.p-column-header-content:has(.p-checkbox) {
    justify-content: center;
}

.p-filter-column {
    text-align: center !important;
    padding: 0.40rem !important;

    .p-inputtext {
        padding: 0.45rem 0.75rem;
    }

    .p-column-filter-clear-button {
        display: none;
    }

    .p-multiselect-label {
        padding: 0.25rem 0.75rem;
        color: black;
    }

}

.p-autocomplete input {
    width: 100%;
}

.p-button {
    &:hover {
        background: $color-template !important;
        border-color: $color-template
    }

    &-danger {
        &:hover {
            background-color: $color-red !important;
            border-color: $color-template;
            /* Assuming you want the border color from the template */
        }
    }
}

.p-buttonset .p-button {
    border-color: white;
}

.ck-content {
    min-height: 30vh;
}

// .p-button.p-button-icon-only{
//     height: 3.2rem;
// }

.p-autocomplete-multiple-container {
    width: 100% !important;
}

.input-spacing {
    margin-right: 10px;
}

.dropdown-spacing {
    margin-right: 10px;
}

.p-resizable-column {
    padding: 5px !important;
    font-weight: 600 !important;
    font-size: 15px !important;
}

//file upload css
.p-fileupload-file-badge {
    display: none !important;
}



.p-fileupload .p-fileupload-content {
    padding: 0px !important;
}

.p-message-icon {
    display: none;
}

//questions css
.questions-body {
    padding: 5px;
}
button-spacing {
    margin-top: 20px;
    transition: all 0.3s;
}
button-spacing:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.top-right-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    transition: all 0.3s;
}
.top-right-button:hover {
    background-color: #ddd;
}

.total-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.horizontal-container {
    display: flex;
    align-items: center;
    gap: 10px;
    // padding-top: 10px;
    margin-top: 5px;
    width: 95%;
    margin-right: 5px;
}

.horizontal-container label {
    width: 20%;
    text-align: right;
    font-weight: 600;
    margin-right: 10px;
}

.horizontal-container .p-inputtextarea,
.horizontal-container .p-dropdown,
.horizontal-container .p-inputtext {
    width: 60%;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    transition: all 0.3s;
}
.p-inputtextarea:focus,
.p-dropdown:focus,
.p-inputtext:focus {
    border-color: #667eea;
    box-shadow: 0 0 0 3px rgba(102, 126, 234, 0.3);
}
.delete-icon .p-button-danger {
    margin-top: 30px;
    margin-left: 10px;
}

.field {
    padding-right: 10px;
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
}

.savebtn {
    margin-left: 5px;
    background-color: #667eea;
    color: #ffffff;
    transition: all 0.3s;
}

.savebtn:hover {
    background-color: #5a67d8;
}
.questionsDropdown {
    width: 220px;
}

//forms css

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid #e5e5e5;
    padding: 10px;
    // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    // background-color: #EAF4E8;
    // background-color: #FAFAF4;
    // border-radius: 10px;
}
.button-group {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
}
.view-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
}

.p-message-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    max-width: 100%;
    overflow: auto;
}

.p-message-summary,
.p-message-detail {
    grid-column: 2;
}

.p-message-close {
    grid-column: 1;
    top: -45px;
}
.form-container {
    display: grid;
    place-items: center;
    margin-top: 20px;
    // width: 80%;
}

.default-fields {
    padding-bottom: 10px;
    width: 400px
}
.question {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
}

.option-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 15px;
    margin-top: 7px;
}

.field-container .option-container label {
    margin-left: 10px;
}

.field-container .p-dropdown {
    width: 250px;
    margin-top: 10px;
}

.field-container .p-inputtextarea {
    width: 350px;
    margin-top: 10px;

}

#dropdown-4 {
    margin-top: 10px;
}

.form-heading {
    margin-bottom: 20px;
    text-align: center;
    color: #007bff;
}

.questions-container {
    margin-top: 20px;
    width: 100%;
}

.editor-container {
    align-items: center;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.templates-dropdown {
    margin-top: 10px;
}

.templates-container p {
    margin-left: 1% !important;

}

.ckeditor-container {
    width: 90%;
    margin: 0 auto;
}

.editor-style {
    font-family: 'Arial, sans-serif';
    font-size: '16px';
    line-height: '1.5';
    color: '#333';
    border: '1px solid #e0e0e0';
}

.templateStyle {
    border-bottom: 2px solid;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    background-color: white;
}

.slot-booking-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 16px;
}

.time-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 8px;
    padding: 8px;
    border: 1px solid #ccc;
}

.slot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #dff0d8;
    cursor: pointer;
}

.slot.booked {
    background-color: #f2dede;
    cursor: not-allowed;
}

.my-calendar-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

//dialog css
.custom-dialog .p-field {
    margin-bottom: 1rem;
}

.custom-dialog label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.custom-dialog .p-inputtext,
.custom-dialog .p-calendar {
    width: 100%;
    padding: 0.5rem;
}


.custom-dialog-header {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
}


.custom-dialog {
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

//calendar-form
.calendar-sidebar {
    background-color: #f4f4f4;
    padding: 20px;
    height: 100vh;
    overflow-y: auto;
}

.calendar-form-container {
    background-color: #fff;
    padding: 20px;
    border-left: 1px solid #ddd;
    position: relative;
    /* Add this line if it's missing */
}

.calendar-form-container .close-button {
    background: none;
    border: 1px solid #ccc;
    border-radius: 50%;
    /* Circle shape */
    padding: 2px 5px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
}

.calendar-form-container.close-button:hover {
    background-color: #f5f5f5;
    /* A slight hover effect */
}

.calendar-form-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.calendar-form {
    /* Add any additional styling to the form if needed */
}

.calendar-form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

/* Styling for the input fields, select boxes, etc. */
.calendar-form-group input,
.calendar-form-group select {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.calendar-form-group .p-button.p-button-icon-only {
    height: 2.8rem;
}

.calendar-form-container .button {
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.calendar-form-container .button:hover {
    background-color: white;
    color: black;
    border: 2px solid #4CAF50;
}

.patient-auto-complete .p-button.p-button-icon-only {
    height: 1.9rem;
}

.patient-nav-bar-main {
    width: 100%;
    position: fixed;
    top: 60px;
    height: 60px;
    z-index: 100;
    background-color: #dedede;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
}

.calendar-form-group-row {
    display: flex;
    justify-content: space-between;
}

.calendar-form-group-time {
    flex: 1;
    margin-right: 10px;
}


.calendar-form-group-time:last-child {
    margin-right: 0;
}

.calendar-form-group-date {
    width: 100%;
    margin-bottom: 10px;
}

.patient-signature-button {
    padding: 10px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.patient-signature-button-dialog {
    width: fit-content;
    text-align: center;
    padding: 10px;
}

.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
    // font-weight: bold;
    justify-content: center;
    font-size: 16px;
}

.entered_data {
    display: inline-block;
    font-weight: 700;
}

.view-close-icon{
    font-size:18px;
    border: none;
    background-color:rgb(60, 97, 119) ;
    color: white;
    transition: background-color 0.5s;
    display: flex;
    margin-right: 10px;
    margin-top: 2px;
    font-size: 22px;
}
  .view-close-icon:hover {
    background-color: rgb(90, 127, 149); 
}


.display_form_data {
    @media screen and (max-width: 700px) {
        font-size: 10px;
    }
    @media screen and (min-width: 769px) and (max-width: 820px) {
        padding: 20px;
    }
    @media screen and (min-width: 821px) and (max-width: 1366px) {
        font-size: 18px;
        padding: 25px;
    }
    @media screen and (min-width: 1367px) and (max-width: 1439px) {
        font-size: 20px;
        padding: 25px;
    }
    @media screen and (min-width: 1440px) {
        font-size: 25px;
        padding: 30px;
    }
}

.p-datatable .p-datatable-tbody{
    .pending_payment{
        background-color: #e2c9fb;
    }
    .failed_payment {
        background-color: #f9d8da;
    }
    .completed_payment{
        background-color: #c6f1e7;
    }
}

//css for buttons in the login component 
.scale {
    transform: scale(0.80);

    button {
        white-space: nowrap;
    }
}

.text-info {
    color: rgb(47, 39, 131) !important;
}

.login-container .login4 .card .card-body img {
    width: 250px;
    height: 250px;
}

.p-dialog-header {
    background-color: $table-header-bg-color !important;
    color: $table-header-text-color !important;
    height: 4rem !important;
    text-align: left;
    padding: 0.5rem;
}

.p-dialog .p-dialog-header {
    padding: 1rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: $table-header-text-color !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: none;
    border-color: transparent;
}

.p-dialog .p-dialog-content {
    padding: 0 1rem .5rem 1rem;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
    height: 100%;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
    height: 100%;
}


.ResizePanel-module_ResizeContentHorizontal__1gGbA {
    max-width: 100%;
}

.p-button.p-fileupload-choose {
    padding: 0.75rem;
}

.bulkuploadbutton .p-button.p-fileupload-choose{
    padding: 0.75rem;
    width: 26.3%;
}

//css for auto complete field dropdown panel if options having long text
.p-autocomplete-panel .p-autocomplete-item {
    white-space: normal;
    word-wrap: break-word;
    padding: 0.50rem 1rem !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    font-family: $font-type;
}

.textElipses>a>img {
    width: 40px;
    height: 20px;
    margin: 5px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    text-transform: capitalize;
    font-size: 1.15rem;
}

.inline-multi-select .p-multiselect .p-multiselect-label {
    color: rgb(64, 56, 56);
}

.grid_header {
    color: $color-template;
    font-weight: 600;
}


// / For screens larger than 1700px, hide the small header /
@media screen and (min-width: 1600px) {
    .screen-header-small {
        display: none;
    }
}

// / For screens smaller than 1700px, hide the large header /
@media screen and (max-width: 1599px) {
    .screen-header-large {
        display: none;
    }
}

//css for action buttons and pagination for screen headers
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    min-width: 2rem;
    width: 2.3rem;
    height: 2.3rem;
    margin: 0.12rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2.3rem;
    height: 2.4rem;
    margin: 0.12rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-paginator .p-dropdown {
    height: 2.5rem;
}

.p-button.p-button-sm {
    padding: 0.5rem 1rem;
}


//funnel filter changes
.p-column-filter-menu {
    .pi-filter-icon {
        visibility: hidden;
        opacity: 0;
    }
}
.p-sortable-column:hover{
    .pi-filter-icon {
        color: #343a40;
    }
}
.p-sortable-column:hover, .p-resizable-column:hover ,.p-column-filter-menu-button-open ,.p-column-filter-menu-button-active{
    .pi-filter-icon {
        visibility: visible;
        opacity: 1;
    }

}

// / Hide all sort icons initially /
.p-sortable-column .p-sortable-column-icon {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s ease-in-out;
}

// / Show sort icons on hover /
.p-sortable-column:hover .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
}

// / Keep sort icons visible when a column is sorted /
.p-sortable-column.p-highlight .p-sortable-column-icon {
    visibility: visible;
    opacity: 1;
    transition: none;
}

.family_members  .p-datatable .p-datatable-thead > tr > th{
    padding: 0.5rem !important;
}


.patient-img-upload .p-button-label.p-clickable {
    max-width: 85px !important;
    text-overflow: ellipsis !important;
    max-height: 30px !important;
    overflow: hidden !important;
}

.text-capitalize-first-letter::first-letter,
.text-capitalize-first-letter .p-multiselect-item-label::first-letter {
    text-transform: uppercase;
}